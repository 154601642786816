<template>
  <nav
    class="p-3 d-flex justify-content-between align-items-center transition-duration gap-3"
    id="nav-mobile"
    :class="$attrs.class">
    <template
      v-if="!isLoggedIn">
      <section>
        <span class="fs-14 fw-700 text-white d-block">Selamat Datang</span>
        <span class="fs-12 fw-400 text-white">Masuk untuk cek kesehatan Anda</span>
      </section>
      <router-link
        to="/login"
        class="btn btn-register">
        Masuk
      </router-link>
    </template>
    <template
      v-else>
      <section class="flex-fill">
        <span class="fs-14 fw-700 text-white d-block">Hai {{ user.pas_nama }}!</span>
        <div class="marquee">
          <p class="fs-12 fw-400 text-white">{{ user.salam_pembuka }}</p>
        </div>
      </section>

      <section
        class="d-flex align-items-center">
        <!-- <a
          href="#"
          class="me-3 text-primary"
          v-if="timeToInstall"
          @click="installNow">
          <i class="ri-install-line ri-lg"></i>
        </a> -->
        <router-link
          :to="{ name: 'Keranjang' }"
          class="me-3 with-number text-white"
          :class="{
            active: cart.arr_paket.length
          }">
          <i class="ri-shopping-cart-fill ri-lg" />

          <span
            class="count">
            {{ cart.arr_paket.length }}
          </span>
        </router-link>
        <router-link
          :to="{ name: 'Notifications' }"
          class="me-3 with-number text-white"
          :class="{
            'active': notificationCount
          }">
          <img
            src="@/assets/icon/notification-bing.svg"
            alt="Notifikasi"
            class="icon-bell"
          />

          <span class="count">
            {{ notificationCount }}
          </span>
        </router-link>
        <router-link
          :to="{ name: 'Profil' }">
          <img
            v-if="user.foto"
            class="avatar"
            :src="user.foto"
            :alt="user.pas_nama">
          <img
            v-else
            class="avatar"
            src="@/assets/user-placeholder.png"
            :alt="user.pas_nama">
        </router-link>
      </section>
    </template>
  </nav>
  <!-- divider -->
  <div class="d-none my-3 mt-md-0 pt-5 pt-md-0"></div>
</template>

<script>
import { user, isLoggedIn } from '@/core/auth'
import { limitText } from '@/core/utility'
import { analytics, getFirebaseToken } from '@/core/firebase-messaging'
import { notificationCount } from '@/core/notifications'
import { timeToInstall, promptInstalasiNative } from '@/core/installation'
import { cart, getCart } from '@/core/cart'
import { onMounted } from '@vue/runtime-core'

export default {
  setup () {
    onMounted(async () => {
      if (cart.value.arr_paket.length < 1) {
        await getCart()
      }
    })

    const getToken = async () => {
      await getFirebaseToken()
    }

    const installNow = () => {
      analytics.logEvent('instalasi_pwa_clicked')
      promptInstalasiNative.value.prompt()
    }

    return {
      isLoggedIn,
      user,
      getToken,
      notificationCount,
      timeToInstall,
      installNow,
      cart,
      limitText
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/variables';

nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  h1 {
    font-size: 1.25rem;
    font-weight: bold;
  }

  img.avatar {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    object-fit: cover;
  }

  a {
    display: flex;
  }

  .with-number {
    position: relative;

    .count {
      position: absolute;
      left: 50%;
      display: none;
      border: 1px solid $danger;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: .25rem;
      background: $danger;
      color: white;
      font-size: .9rem;
    }

    &.active {
      i {
        color: $secondary;
      }

      .count {
        display: flex;
        justify-content: center;
        align-items: center;
        top: 40%;
      }
    }
  }
}

.btn-register {
  background: white!important;
  border: 1px solid #E2E8F0;
  border-radius: 100px;
  color: #007A98 !important;
}

.bg-gradient-mine {
  background: rgba(0,0,0,0.5);
  background: -moz-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.5)), color-stop(100%, rgba(255,255,255,0)));
  background: -webkit-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%);
  background: -o-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%);
  background: -ms-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%);
  background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff', GradientType=0 );
}

.transition-duration {
  transition-duration: 0.5s;
}

.marquee {
  height: 14.5px;
  overflow: hidden;
  position: relative;
}

.marquee p {
  position: absolute;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  margin: 0;
  text-align: center;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -moz-animation: scroll-left 2s linear infinite;
  -webkit-animation: scroll-left 2s linear infinite;
  animation: scroll-left 20s linear infinite;
}

@-moz-keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
</style>
