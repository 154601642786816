<template>
  <div id="main">
    <navbar-mobile
    class="d-md-none"
    :class="classNavbarMobile" />
    <carousel />
    <!-- <hero /> -->
    <main-header />
    <request-notif-permission />
    <main-service />
    <!-- <main-best-sellers /> -->
    <!-- <main-vouchers /> -->
    <!-- <main-package /> -->
    <main-flash-sale />
    <!-- <main-articles /> -->
    <!-- <main-store-links /> -->
    <!-- <download-app /> -->
  </div>
  <app-footer />
</template>

<script>
// import Hero from '@/components/home/Hero'
import MainHeader from '@/components/home/MainHeader'
import Carousel from '@/components/home/Carousel'
import MainService from '@/components/home/MainService'
// import MainVouchers from '@/components/home/MainVouchers'
import MainFlashSale from '@/components/home/MainFlashSale'
// import MainPackage from '@/components/home/MainPackage'
// import MainArticles from '@/components/home/MainArticles'
// import MainStoreLinks from '@/components/home/MainStoreLinks'
// import MainBestSellers from '@/components/home/MainBestSellers'
// import DownloadApp from '@/components/DownloadApp'
import NavbarMobile from '@/components/NavbarMobile'
import RequestNotifPermission from '@/components/RequestNotifPermission.vue'
import AppFooter from '@/components/Footer'
import { title } from '@/core/page'
import { nextTick, onMounted, ref, computed } from 'vue'

export default {
  components: {
    // Hero,
    MainHeader,
    Carousel,
    MainService,
    // MainVouchers,
    MainFlashSale,
    // MainPackage,
    // MainArticles,
    // MainStoreLinks,
    // MainBestSellers,
    // DownloadApp,
    AppFooter,
    NavbarMobile,
    RequestNotifPermission
  },
  setup () {
    const defaultSliderHeight = computed(() => {
      if (window.screen.width < 425) {
        return 230
      }

      if (window.screen.width < 576) {
        return 300
      }

      if (window.screen.width < 768) {
        return 400
      }

      if (window.screen.width < 992) {
        return 500
      }

      if (window.screen.width < 1200) {
        return 600
      }

      if (window.screen.width < 1400) {
        return 700
      }

      return 800
    })
    const classNavbarMobile = ref('bg-gradient-mine')

    onMounted(() => {
      nextTick(() => {
        title.value = null
      })

      document.addEventListener('scroll', () => {
        const calculated = defaultSliderHeight.value - Math.ceil(window.scrollY)
        const estimated = Math.ceil(window.scrollY)
        const threshold = Math.ceil(defaultSliderHeight.value / 4)
        const stopScrollAt = document.body.offsetHeight - window.innerHeight

        if (stopScrollAt !== estimated) {
          if (estimated >= threshold && window.screen.width < 768) {
            classNavbarMobile.value = 'bg-primary'
            document.getElementById('my-slider-inner').style.opacity = 0
            document.getElementById('my-slider-inner').style.transitionDuration = '0.5s'
          } else {
            document.getElementById('my-slider').style.height = calculated + 'px'
            document.getElementById('my-slider-inner').style.opacity = 1
            classNavbarMobile.value = 'bg-gradient-mine'
          }
        }
      })
    })

    return {
      classNavbarMobile
    }
  }
}
</script>

<style src="@/static/css/home/index.css"></style>
<style>
.modal-lg {
  max-width: 83% !important;
}

@media (min-width: 360px) and (max-width: 767px) {
  .modal-lg {
      max-width: 100% !important;
  }
}

#hero-header {
  height: unset !important;
}
</style>
