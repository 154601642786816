<template>
  <div id="main-voucher" v-if="aktiveFlashSale()">
    <div class="container">
      <div class="header-content d-flex justify-content-between align-items-center pt-3 pb-2">
        <div class="d-flex align-items-center gap-2">
          <i class="ri-flashlight-fill fs-24 lh-24 fw-700"></i>
          <div class="title-content-package fw-700">
            Flash Sale
          </div>
          <div class="timer bg-dark text-white fw-600 fs-14 lh-16">
            {{ countDown }}
          </div>
        </div>
        <router-link :to="{ name: 'FlashSale' }">
          <button class="btn btn-show-all text-center fw-500 fs-12 lh-12 border-0">
            Lihat Semua
          </button>
        </router-link>
      </div>
      <div class="row flex-nowrap overflow-auto pb-3 pb-md-5">
        <Loading v-if="loading.flashSale" />
        <!-- <Empty v-else-if="!flashSale || aktiveFlashSale().length === 0" :message="'Saat ini belum ada Flash Sale untuk Anda.'"/> -->
        <div v-else v-for="datum in aktiveFlashSale()[0].detail" :key="datum" class="col-12 col-md-6 pt-2 pb-2">
          <div class="card">
            <div class="card-body p-0">
              <div class="d-flex flex-column">
                <div class="border-radius-10">
                  <img class="icon-vouchers" :src="datum.image">
                </div>
                <div class="p-3">
                  <div class="row">
                    <div class="d-flex align-items-center justify-content-between">
                      <span class="fs-5">Rp. {{ parseInt(datum.harga_netto).toLocaleString('id-ID') }}</span>
                      <span class="text-muted small">{{ datum.terjual }} Terjual</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="d-flex align-items-center">
                      <span class="text-muted text-decoration-line-through small">Rp. {{ parseInt(datum.harga_bruto).toLocaleString('id-ID') }}</span>
                    </div>
                  </div>
                  <div class="row px-2 mt-2">
                    <button class="btn btn-warning text-white px-4 ms-auto" :disabled="!parseInt(aktiveFlashSale()[0].aktif) || loadingBuy" @click="buy(datum.id_flashsale, datum.jenis)">
                      KLAIM
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <modal-flash-sale :selectedFlashSaleId="selectedFlashSaleId"/>
</template>

<script>
import { getFlashSale, buyFlashSale, flashSales, loading, getDetailFlashSale } from '@/core/flashSale'
import { onMounted, ref, nextTick } from 'vue'
import Loading from '@/components/utility/Loading'
// import Empty from '@/components/utility/Empty'
import { selectedBranch } from '@/core/subDivisions'
import { useRouter } from 'vue-router'
import ModalFlashSale from '@/components/home/flashSale/ModalFlashSale.vue'
import { Modal } from 'bootstrap'
import moment from 'moment'
import { token } from '@/core/auth'

export default {
  components: {
    Loading,
    ModalFlashSale
    // Empty
  },

  setup () {
    const router = useRouter()

    const aktiveFlashSale = () => {
      if (flashSales.value) {
        return flashSales.value.filter((element) => { return parseInt(element.aktif) })
      }
      return null
    }
    const loadingBuy = ref(false)
    const selectedFlashSaleId = ref(null)
    const countDown = ref('00 : 00 : 00')

    const addLeadingZero = (number) => {
      if (number < 10) {
        return `0${number}`
      }
      return `${number}`
    }

    const countDownTimer = () => {
      const duration = moment.duration(moment(aktiveFlashSale()[0].jam_selesai, 'HH:mm:ss').diff(moment()))
      if (duration.asSeconds() > 0) {
        setTimeout(() => {
          countDown.value = `${addLeadingZero(duration.hours())} : ${addLeadingZero(duration.minutes())} : ${addLeadingZero(duration.seconds())}`
          countDownTimer()
        }, 1000)
      }
    }

    const buy = async (id, jenis) => {
      loadingBuy.value = true

      if (!token.value) {
        return router.push({
          name: 'Login'
        })
      }

      if (jenis === 'PAKET') {
        if (!selectedBranch.value) {
          return router.push({
            name: 'SelectBranch',
            query: {
              redirectTo: '/'
            }
          })
        }
      } else if (jenis === 'KODEPXFREE') {
        const flashSale = await getDetailFlashSale(id)
        if (flashSale?.data[0]?.id_flashsale) {
          selectedFlashSaleId.value = flashSale?.data[0]?.id_flashsale
          const modal = Modal.getOrCreateInstance(
            document.querySelector('#modal-flash-sale')
          )
          modal.show()
        }
        loadingBuy.value = false
        return
      }
      buyFlashSale(id)
      loadingBuy.value = false
    }

    onMounted(async () => {
      await getFlashSale()

      nextTick(() => {
        countDownTimer()
      })
    })

    return {
      loading,
      flashSales,
      aktiveFlashSale,
      buy,
      selectedFlashSaleId,
      countDown,
      loadingBuy
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-vouchers {
  width: 100%;
  height: auto;
}

.card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.header-content {
  i {
    color: #F79643;
  }

  .title-content-package {
    color: #F79643;

    @media (max-width: 580px) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .timer {
    border-radius: 8px;
    padding: 4px;
  }

  .btn-show-all {
    padding: 6px 12px;
    height: 28px;
  }
}

.row {
  // height: 300px;
  @media (max-width: 580px) {
    // height: 300px;
    // overflow-x: scroll;
    // overflow-y: hidden;
  }

  .voucher {
    border-radius: 14px !important;

    .img-flash-sale {
      @media (max-width: 580px) {
        width: 289px;
        height: 136px;
      }
    }

    .card-body {
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;

      .card-title {
        margin-bottom: 4px;

        .card-title-sold {
          color: #718096;
        }
      }

      .card-discount {
        margin-bottom: 8px;
      }
    }

    .card-button {

      .btn-orange {
        width: 100%;
      }
    }
  }
}
</style>
