<template>
  <footer
    id="footer"
    class="d-none d-md-block">
    <div class="container">
      <div class="row pb-3">
        <div class="col-md-6">
          <div class="info">
            <div class="d-flex flex-column bd-highlight mb-3">
              <div class="p-2 bd-highlight">
                <img class="footer-logo" src="@/assets/image.png" />
              </div>
              <div class="p-2 bd-highlight">
                <div class="address">
                  Dharmawangsa 66 Surabaya 60286
                </div>
              </div>
              <!-- <div class="p-2 bd-highlight">
                <div class="d-flex flex-column bd-highlight">
                  <div class="working-hour title-hour" style="">Hour's</div>
                  <div class="working-hour">Senin-Jumat : 06.00-21.00</div>
                  <div class="working-hour">Sabtu : 06.00-20.00</div>
                  <div class="working-hour">Minggu : 07.00-13.00 *Khusus cabang Surabaya Dharmawangsa dan Jember</div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <!-- Product -->
        <!-- <div class="col-md-2">
          <div class="group-menu">
            <div class="d-flex flex-column bd-highlight mb-3">
              <div class="p-2 pt-3 pb-3 bd-highlight">
                <div class="title-group">Product</div>
              </div>
              <div class="p-2 bd-highlight">
                <div class="title-menu">Features</div>
              </div>
              <div class="p-2 bd-highlight">
                <div class="title-menu">Platform</div>
              </div>
              <div class="p-2 bd-highlight">
                <div class="title-menu">Pricing</div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- End Product -->
        <!-- Company -->
          <!-- <div class="col-md-2">
          <div class="group-menu">
            <div class="d-flex flex-column bd-highlight mb-3">
              <div class="p-2 pt-3 pb-3 bd-highlight">
                <div class="title-group">
                    Company
                </div>
              </div>
              <div class="p-2 bd-highlight">
                <div class="title-menu">About Us</div>
              </div>
              <div class="p-2 bd-highlight">
                <div class="title-menu">Blog</div>
              </div>
              <div class="p-2 bd-highlight">
                <div class="title-menu">Jobs</div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- End Company -->
        <!-- Support -->
          <!-- <div class="col-md-2">
          <div class="group-menu">
            <div class="d-flex flex-column bd-highlight mb-3">
              <div class="p-2 pt-3 pb-3 bd-highlight">
                <div class="title-group">
                    Support
                </div>
              </div>
              <div class="p-2 bd-highlight">
                <div class="title-menu">Contact</div>
              </div>
              <div class="p-2 bd-highlight">
                <div class="title-menu">Tutorials</div>
              </div>
              <div class="p-2 bd-highlight">
                <div class="title-menu">Doc</div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- End Support -->
      </div>
      <hr class="footer-divider">
      <div class="bottom-footer">
          <div class="d-flex justify-content-between align-items-center">
        <div class="company-name">Parahita Diagnostic Center © 2020 | PT. CITA MULIA</div>
          <div class="social-account">
            <div class="d-flex justify-content-between">
              <img class="social-icon" src="@/assets/social-icon/instagram.png" />
              <img class="social-icon" src="@/assets/social-icon/facebook.png" />
              <img class="social-icon" src="@/assets/social-icon/twitter.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
