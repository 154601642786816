<template>
  <section class="container-xxl px-0 px-xxl-3">
      <div id="my-slider" class="carousel slide bg-primary">
        <ol class="carousel-indicators py-1 gap-2">
          <li v-for="item in dataCarousel.length" :key="item">
            <div class="indicator"></div>
          </li>
        </ol>

        <div class="my-slider" id="my-slider-inner">
          <div v-for="item in dataCarousel" :key="item" role="button">
            <img :src="item.image" class="d-block img-carousel" :alt="item.judul">
          </div>
        </div>

        <!--begin::Slider button-->
        <!-- <button class="btn btn-icon btn-active-color-primary" id="prevButton">
            <span class="svg-icon svg-icon-3x">
                <i class="ri-arrow-left-s-line"></i>
            </span>
        </button> -->
        <!--end::Slider button-->

        <!--begin::Slider button-->
        <!-- <button class="btn btn-icon btn-active-color-primary" id="nextButton">
            <span class="svg-icon svg-icon-3x">
                <i class="ri-arrow-right-s-line"></i>
            </span>
        </button> -->
        <!--end::Slider button-->
      </div>
  </section>

</template>

<script>
import axios from '@/plugins/axios'
import { onMounted, ref, nextTick } from 'vue'
import { tns } from '../../../node_modules/tiny-slider/src/tiny-slider'

export default {
  setup () {
    const dataCarousel = ref([])
    const KEY = 'main.sliders'

    onMounted(async () => {
      try {
        dataCarousel.value = JSON.parse(localStorage.getItem(KEY)) || []
        const { data } = await axios.get('app/slider')

        if (data.success) {
          dataCarousel.value = data.data
          localStorage.setItem(KEY, JSON.stringify(dataCarousel.value))
          nextTick(() => {
            tns({
              container: '.my-slider',
              items: 1,
              loop: true,
              speed: 500,
              autoplay: true,
              lazyload: true,
              autoplayTimeout: 3000,
              autoplayButtonOutput: false,
              mouseDrag: true,
              controls: false,
              edgePadding: 0,
              navContainer: '.carousel-indicators'
            })

            // update og:image haha
            document.querySelectorAll('meta[property="og:image"]').forEach(dom => {
              dom.remove()
            })

            dataCarousel.value.forEach(slider => {
              const ogImage = document.createElement('meta')
              ogImage.setAttribute('property', 'og:image')
              ogImage.content = slider.image

              document.head.appendChild(ogImage)
            })
          })
        }
      } catch (error) {
        console.error(error)
      }
    })

    return {
      dataCarousel
    }
  }
}

</script>

<style scoped lang="scss">
/* ini untuk menghindari CLS --web vitals-- */
.carousel {
  height: 230px;
  overflow-y: hidden;

  @media screen and (min-width: 425px) {
    height: 300px;
  }

  @media screen and (min-width: 576px) {
    height: 400px;
  }

  @media screen and (min-width: 768px) {
    height: 500px;
  }

  @media screen and (min-width: 992px) {
    height: 600px;
  }

  @media screen and (min-width: 1200px) {
    height: 700px;
  }

  @media screen and (min-width: 1400px) {
    height: 800px;
  }
}

.carousel-indicators {
  margin-bottom: 25px;
}

.indicator {
  width: 8px;
  height: 8px;
  background: #ffffff;
  border-radius: 100px;
}

.tns-nav-active .indicator {
  background-color: #FFFFFF;
  border-radius: 100px;
  width: 18px;
  height: 8px;
}

/* bagian controls */
#nextButton, #prevButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  background: #FFFFFF;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.06);
  border-radius: 100px;
}

#nextButton {
  right: 0;
}

#prevButton {
  left: 0;
}

.my-slider {
  transition-duration: 1s;
}

.my-slider img {
  width: 100%;
  height: 230px;
  object-fit: cover;

  @media screen and (min-width: 425px) {
    height: 300px;
  }

  @media screen and (min-width: 576px) {
    height: 400px;
  }

  @media screen and (min-width: 768px) {
    height: 500px;
  }

  @media screen and (min-width: 992px) {
    height: 600px;
  }

  @media screen and (min-width: 1200px) {
    height: 700px;
  }

  @media screen and (min-width: 1400px) {
    height: 800px;
  }
}
</style>
