<template>
  <div id="main-service" class="mt-md-5">
    <div class="container px-3">
      <div
        v-if="loading.services && !services.length"
        class="card-body py-3 px-3 py-sm-4 px-sm-4">
        Loading..
      </div>
      <div v-else>
        <!-- <div class="row">
          <div class="col mb-3 mb-md-5">
            <input
              type="text"
              class="form-control bg-transparent rounded-pill"
              placeholder="Cari layanan...">
          </div>
        </div> -->
        <div class="row">
          <div
            class="col-3 text-center mb-4"
            v-for="(service, i) in services"
            :key="i"
          >
            <a v-if="parseInt(service.id_group) == 11" href="https://dokter.labparahita.com" target="_blank">
              <img
                class="icon-service"
                :src="service.icon"
                onload="this.style.backgroundColor = 'transparent'"
              />
              <span class="d-block fw-400 fs-11 color-neutral-700 mt-2">FPP Dokter</span>
            </a>

            <router-link
              v-else
              :to="generateUrl(parseInt(service.id_group))">
              <img
                class="icon-service"
                :src="service.icon"
                onload="this.style.backgroundColor = 'transparent'"
              />
              <span class="d-block fw-400 fs-11 color-neutral-700 mt-2">{{ service.nama }}</span>
            </router-link>
          </div>

          <!-- <router-link
            :to="{ name: 'Fpp' }"
            v-if="services && services.length"
            class="card card-service">
            <img
              class="icon-service"
              src="@/assets/services/chest.png"
              onload="this.style.backgroundColor = 'transparent'"
            />
            <div class="title-service">
              <p>FPP</p>
            </div>
          </router-link> -->

          <!-- <router-link
            :to="{
              query: {
                ...$route.query,
                showMainServiceModal: true
              }
            }"
            v-if="true || services && services.length && services.length > 5"
            class="col d-flex flex-column align-items-center">
            <img
              class="icon-service"
              src="@/assets/services/plus.png"
              onload="this.style.backgroundColor = 'transparent'"
            />
            <div class="title-service">
              <p>Lainnya</p>
            </div>
          </router-link> -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { services, loadServices, loading } from '@/core/service'
import { onMounted } from 'vue'

export default {
  setup () {
    // ini bisa gini kalau pakai compose API
    onMounted(() => {
      loadServices()
    })

    const generateUrl = (id_group) => {
      if (id_group === 7) { // FPP
        return {
          name: 'Fpp'
        }
      } else if (id_group === 10) { // MCU
        return {
          name: 'MCU'
        }
      } else if (id_group === 9) { // promo
        return {
          name: 'Promo'
        }
      } else if (id_group === 5) { // Home Service
        return {
          name: 'HomeServiceSelectBranch'
        }
      } else if (id_group === 11) { // Promo
        return {
          name: 'MCU'
        }
      } else {
        return {
          name: 'SubService',
          params: {
            id_layanan: id_group
          }
        }
      }
    }

    return {
      services,
      loadServices,
      loading,
      generateUrl
    }
  }
}
</script>

<style lang="scss" scoped>
#main-service {

  .form-control {
    width: 400px;
    height: 40px;
    border: 1px solid #E2E8F0;

    @media (max-width: 580px) {
      width: 100%;
    }
  }

  .form-control:focus {
    box-shadow: none !important;
  }

  .form-control::-webkit-input-placeholder {
    font-family: 'Inter';
    font-size: 14px;
    line-height: 24px;
    color: #A0AEC0;
  }

  .icon-service {

    @media (max-width: 580px) {
      width: 42px;
      height: 42px;
    }
  }

  .title-service {

    @media (max-width: 580px) {
      font-size: 11px;
      line-height: 14px;
      color: #4A5568;
    }
  }
}
</style>
